<template>
  <v-card 
    class="travel-card font-weight-bold text-center white--text position-relative"
    :style="{ backgroundImage: 'url(' + bg + ')' }"
  >    
    <div class="card-title deep-orange darken-1 d-flex align-items-center justify-content-center py-2 px-4">
      {{ $t(title) }}
    </div>
    <div class="card-content">
      <p class="mb-0">{{ $t(text) }}</p>
      <p class="mb-0">{{ $t(date) }}</p>
      <RateStar :starNumber="rate" color="#fff"></RateStar>
    </div>
    <p class="price font-weight-bold fs20">${{ $t(price) }}</p>
  </v-card>
</template>

<script>
import RateStar from '@/components/RateStar.vue';
export default {
  name: "TravelCard",
  components: {
    RateStar
  },
  props: {
    title: String,
    text: String,
    date: String,
    price: [Number, String],
    bg: String,
    rate: [Number, String]
  }
}
</script>

<style lang="scss" scoped>
  .travel-card {
    width: 200px;
    height: 250px;
    margin: 10px;
    background-size: cover;
    @media screen and (max-width: 480px)  {
      width: calc(50% - 10px);
      margin: 5px;
    }
    &::before {
        content: "";
        background: transparent linear-gradient(180deg, #00000000 0%, #000000D9 100%) 0% 0% no-repeat padding-box;
        width: 100%;
        height: 80%;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .card-title {
      border-radius: 5px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      white-space: nowrap;
    }
    .card-content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 40%;
      white-space: nowrap;
    }
    .price {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      white-space: nowrap;
    }
  }
</style>