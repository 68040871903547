<template>
    <main class="home-page mx-auto w-100 d-flex">
        <section class="adver-section blue lighten-3 mt-22"></section>
        <section class="main-content w-100 py-5">
            <h5 class="text-center font-weight-bold mb-5">{{ $t("Top suppliers") }}</h5>
            <div class="d-flex justify-content-center flex-wrap">
                <router-link v-for="(card, i) in supplierCards" :to="{ name: handleGetSupplierRouter(card.type), params: { id: card.id } }" tag="a" exact>
                    <SupplierCard
                        :key="i"
                        :text="card.name"
                        :logo="card.image"
                        :bgColor="card.logo_background_color"
                        :titleBgColor="card.logo_title_background"
                        :fontColor="card.font_color"
                    >
                    </SupplierCard>
                </router-link>
            </div>

            <h5 class="text-center font-weight-bold mt-20 mb-5">{{ $t("Best deals") }}</h5>
            <div class="deals-block d-flex align-items-start">
                <div class="d-flex flex-wrap travel-deals">
                    <TravelCard
                        v-for="(deal, key) in filteredCheapestDeals"
                        :key="key"
                        :title="deal.destinationName ? deal.destinationName : deal.rowName"
                        :text="deal.type"
                        :date="handleCalcDataRange(deal.departureDate, deal.dealLength)"
                        :price="deal.dealPricePerPerson ? deal.dealPricePerPerson : deal.price"
                        :bg="generateDealImagePath(deal)" 
                        :rate="deal.hotelRating"
                    >
                    </TravelCard>
                </div>
                <div class="d-flex flex-wrap supplier-deals">
                    <SupplierDealCard
                        v-for="(card, i) in filteredProduct" 
                        :key="i"
                        :headerBgColor="card.supplier_image_background_color"
                        :logo="card.supplier_image"
                        :supplier="card.supplier_name"
                        :calls="card.call_minute"
                        :sms="card.sms" 
                        :network="card.browsing_package"
                        :days="card.usage_time_limit"
                        :price="card.price"
                        :isXbox=false
                        :id="card.id"
                        :supplier_id="card.supplier_id"
                        :name="card.name"
                        :type="card.type"
                        :show_additional_details="card.show_additional_details"
                        :additional_details="card.additional_details"
                        :product_router="handleGetProductRouter(card.supplier_type)"
                    >
                    </SupplierDealCard>
                </div>
            </div>
        </section>  
        <section class="adver-section blue lighten-3 mt-22"></section> 
        <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
        ></loading> 
    </main>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import TravelCard from '@/components/endUser/home/TravelCard';
    import SupplierCard from '@/components/endUser/SupplierCard';
    import SupplierDealCard from '@/components/endUser/home/SupplierDealCard';
    import tourismMixin from '@/utility/tourism_mixin';
    import endUser_mixin from '@/utility/endUser_mixin';
    export default {
        name: 'EndUserHome',
        mixins: [tourismMixin, endUser_mixin],
        components: {
            TravelCard,
            SupplierCard,
            SupplierDealCard
        },
        data: () => ({
            search: "",
            supplierCards: [],
            filteredProduct: []
        }),
        computed: {
            ...mapState({
                dealLoading: (state) => state.deal.loading,
                productLoading: (state) => state.product.product_module_loading,
                homeSuppliersData: (state) => state.end_user.homeSuppliersData,
                products: state => state.product.products,
                cheapestDealsData: (state) => state.deal.cheapestDealsAllData
            }),
            loading () {
                return this.dealLoading || this.productLoading;
            },
            filteredCheapestDeals() {
                let filteredCheapestDealsObject = {};
                let filteredCheapestDeals = [];

                if (Array.isArray(this.cheapestDealsData)) {
                    let types = [];

                    this.cheapestDealsData.forEach(el => {
                        let type = el.type;
                        if (!filteredCheapestDealsObject[type]) {
                            filteredCheapestDealsObject[type] = [];
                            filteredCheapestDealsObject[type].push(el);
                        } 
                        filteredCheapestDealsObject[type].push(el);
                    })
                }

                for (var key in filteredCheapestDealsObject) {
                    filteredCheapestDealsObject[key] = filteredCheapestDealsObject[key].sort((a,b) => a.dealPricePerPerson - b.dealPricePerPerson);
                    filteredCheapestDeals.push(filteredCheapestDealsObject[key][0]);
                    if (key=="CHARTER") {
                        filteredCheapestDeals.push(filteredCheapestDealsObject[key][1]);
                    } 
                }

                return filteredCheapestDeals;
            },
        },
        async created () {
            window.addEventListener('resize', this.handCreateSupplierCards);
            window.addEventListener('resize', this.handCreateProductCards);
            
            if (!this.homeSuppliersData.home) 
                await this.getHomeSuppliers();

            this.handleCreateSupplierCards();

            await this.getProducts({'params':
                {
                    tag: 41,
                    supplier_type: [1, 4, 6]
                }
            });

            if (this.products.length) {
                this.tempFilteredProduct = this.products.filter(el => {
                    return el != undefined;
                }).sort((a,b) => a.customer_home_order - b.customer_home_order);

                this.handCreateProductCards();
            }
            if (this.cheapestDealsData.length == 0) {
                await this.getCheapestDealsAll();
            } 
        },
        methods: {
            ...mapActions("end_user", {
                getHomeSuppliers: "getHomeSuppliers"
            }),
            ...mapActions("product", {
                getProducts: 'getProducts',
            }),
            ...mapActions("deal", {
                getCheapestDealsAll: "getCheapestDealsAll"
            }),

            handleCreateSupplierCards() {
                this.supplierCards = this.handleCreateCards(this.homeSuppliersData.home).sort((a,b) => a.customer_home_order - b.customer_home_order);
            },
            handCreateProductCards() {
                this.filteredProduct = this.handleCreateCards(this.tempFilteredProduct);
            },
            handleCreateCards(data) {
                let result = [];
                if (data) {
                    const deviceWidth = window.innerWidth;
                    data.forEach(el => {
                        if (el.type == 1 || el.type == 4 || el.type == 5 || el.type == 6) {
                            if (deviceWidth < 481) {
                                if (el.show_mobile) {
                                    result.push(el);
                                }
                            } else if (deviceWidth < 769) {
                                if (el.show_tablet) {
                                    result.push(el);
                                }
                            } else {
                                if (el.show_destop) {
                                    result.push(el);
                                }
                            }
                        }
                    });
                }
                return result;
            },
            handleCalcDataRange(departureDate, dealLength) {
                return moment(String(departureDate)).format("DD/MM/YYYY") + " - " + this.calcEndDate(departureDate, dealLength)
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handCreateSupplierCards);
            window.removeEventListener('resize', this.handCreateProductCards);
        },
    }
</script>

<style lang="scss" scoped>
.home-page {
    max-width: 1720px; 
    justify-content: space-between;
    padding: 20px 0;
    @media screen and (max-width: 480px)  {
        padding: 5px 0;
    }
    @media screen and (max-width: 1575px)  {
        justify-content: center;
    }
    .adver-section {
        min-width: 250px;
        width: 250px;
        height: 699px;
        @media screen and (max-width: 1575px)  {
            display: none;
        }
    }
    .main-content {
        max-width: 1032px;
        .deals-block {
            // flex-direction: row;
            @media screen and (max-width: 1060px)  {
                justify-content: center;
            }
            @media screen and (max-width: 642px)  {
                flex-direction: column-reverse;
            }
            .travel-deals {
                min-width: 440px;
                max-width: 440px;
                @media screen and (max-width: 1060px)  {
                    min-width: 220px;
                    width: 220px;
                }
                @media screen and (max-width: 642px)  {
                    max-width: 100%;
                    justify-content: center;
                }
                @media screen and (max-width: 642px)  {
                    width: 100%;
                    justify-content: center;
                }
            }
            .supplier-deals {
                @media screen and (max-width: 1060px)  {
                    max-width: 591px;
                }
                @media screen and (max-width: 839px)  {
                    max-width: 394px;
                }
                @media screen and (max-width: 642px)  {
                    max-width: 100%;
                    justify-content: center;
                }
                // @media screen and (max-width: 480px)  {
                //     overflow: hidden;
                //     max-height: 512px;
                // }
            }
            
        }
    }
}
</style>